import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import marina from '../../assets/hotel/marina residency.png';
import marina_copy from '../../assets/hotel/marina residency - Copy.png';
import fezin from '../../assets/hotel/hotel fezin.png';
import fezin_copy from '../../assets/hotel/hotel fezin - Copy.png';
import paramount from '../../assets/hotel/hotel paramount.png';
import paramount_copy from '../../assets/hotel/hotel paramount - Copy.png';
import kingfort from '../../assets/hotel/hotel kingfort.png';
import kingfort_copy from '../../assets/hotel/hotel kingfort - Copy.png';
import casino from '../../assets/hotel/hotel casino.png';
import casino_copy from '../../assets/hotel/hotel casino - Copy.png';
import east_evenue from '../../assets/hotel/east evenue.png';
import east_evenue_copy from '../../assets/hotel/east evenue - Copy.png';
import dimora from '../../assets/hotel/hotel dimora.webp';
import dimora_copy from '../../assets/hotel/hotel dimora - Copy.png';
import white_suite from '../../assets/hotel/hotel white suite.webp';
import white_suite_copy from '../../assets/hotel/hotel white suite - Copy.png';
import nest_residency from '../../assets/hotel/nest residency.webp';
import nest_residency_copy from '../../assets/hotel/nest residency - Copy.jpg';
import south_regency from '../../assets/hotel/south regency.webp';
import south_regency_copy from '../../assets/hotel/south regency - Copy.jpg';
import bluebell from '../../assets/hotel/hotel bluebell.webp';
import bluebell_copy from '../../assets/hotel/hotel bluebell - Copy.jpg';
import select_residency from '../../assets/hotel/select residency.webp';
import select_residency_copy from '../../assets/hotel/select residency - Copy.jpg';
import ImageCard from '../../layouts/ImageCard';

const Hotel = () => {
  const [images, setImages] = useState({
    eastEvenueImg: east_evenue_copy,
    paramountImg: paramount_copy,
    marinaImg: marina_copy,
    fezinImg: fezin_copy,
    kingfortImg: kingfort_copy,
    casinoImg: casino_copy,
    dimoraImg: dimora_copy,
    whiteSuiteImg: white_suite_copy,
    nestResidencyImg: nest_residency_copy,
    southRegencyImg: south_regency_copy,
    bluebellImg: bluebell_copy,
    selectResidencyImg: select_residency_copy,
  });

  useEffect(()=>{
    window.scrollTo({ top: 0});
  })

  useEffect(() => {
    const loadImage = (src, key) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setImages(prevImages => ({ ...prevImages, [key]: src }));
      };
    };

    const imageSources = [
      { src: east_evenue, key: 'eastEvenueImg' },
      { src: paramount, key: 'paramountImg' },
      { src: marina, key: 'marinaImg' },
      { src: fezin, key: 'fezinImg' },
      { src: kingfort, key: 'kingfortImg' },
      { src: casino, key: 'casinoImg' },
      { src: dimora, key: 'dimoraImg' },
      { src: white_suite, key: 'whiteSuiteImg' },
      { src: nest_residency, key: 'nestResidencyImg' },
      { src: south_regency, key: 'southRegencyImg' },
      { src: bluebell, key: 'bluebellImg' },
      { src: select_residency, key: 'selectResidencyImg' },
    ];

    imageSources.forEach(({ src, key }) => loadImage(src, key));
  }, []);

  const hotels = [
    { name: "Hotel Dimora Kozhikode", img: images.dimoraImg, url: "https://www.thedimorahotels.com/dimora-kozhikode/virtual-tour/" },
    { name: "Hotel Fezinn", img: images.fezinImg, url: "https://cybozom.in/360/hotel/fezinn/" },
    { name: "Hotel Paramount Tower", img: images.paramountImg, url: "https://paramounttower.in/virtual-tour/" },
    { name: "Hotel Kingfort", img: images.kingfortImg, url: "https://cybozom.in/360/hotel/kingfort/" },
    { name: "East Evenue", img: images.eastEvenueImg, url: "https://cybozom.in/360/hotel/eastevenue/" },
    { name: "Bluebell Hotel", img: images.bluebellImg, url: "https://cybozom.in/public/360/bluebell/" },
    { name: "Hotel Marina Residency", img: images.marinaImg, url: "https://marinaresidency.com/360/" },
    { name: "Treebo Trend Casino", img: images.casinoImg, url: "https://cybozom.in/360/hotel/casino/" },
    { name: "White Suite Hotel", img: images.whiteSuiteImg, url: "https://whitesuite.in/360/" },
    { name: "Hotel South Regency", img: images.southRegencyImg, url: "https://www.cybozom.site/360/southregency2/" },
    { name: "Nest Residency", img: images.nestResidencyImg, url: "https://www.cybozom.site/360/nest5/" },
    { name: "Select Residency", img: images.selectResidencyImg, url: "https://cybozom.in/public/360/selectresidency/" },
  ];

  const navigateToExternalLink = (url) => {
    window.location.href = url;
  };

  return (
    <div className='mt-24'>
      <h1 className='text-[1.5rem] min-[425px]:text-[2rem] md:text-[3rem] tracking-widest text-[#293461] text-center'>HOTEL</h1>
      <hr className='w-full border border-[#293461]' />
      <div className='mt-10 grid grid-cols-1 min-[300px]:grid-cols-2 md:grid-cols-3 gap-5 sm:gap-10 mx-5 md:mx-10'>
        {hotels.map((hotel, index) => (
          <button key={index} onClick={() => navigateToExternalLink(hotel.url)} className="focus:outline-none">
            <LazyLoad height={200} offset={100} once>
              <ImageCard name={hotel.name} img={hotel.img} />
            </LazyLoad>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Hotel;